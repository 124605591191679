import React from "react";

export const Header = (props) => {
    return (
        <header id="header">
            <div className="intro">
                <div className="overlay">
                    <div className="container intro-text">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <img src="/img/logo-white-sqr.png" alt="Logo" className="header-logo" />
                                <h1>
                                    {props.data ? props.data.title : "Loading"}
                                    <span></span>
                                </h1>
                                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
